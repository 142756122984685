import { render, staticRenderFns } from "./detailIndex.vue?vue&type=template&id=306c5836&scoped=true&"
import script from "./detailIndex.vue?vue&type=script&lang=ts&"
export * from "./detailIndex.vue?vue&type=script&lang=ts&"
import style0 from "./detailIndex.vue?vue&type=style&index=0&lang=less&"
import style1 from "./detailIndex.vue?vue&type=style&index=1&id=306c5836&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "306c5836",
  null
  
)

export default component.exports