

































import Vue from 'vue';
import { mapGetters } from 'vuex';
import DetailHeader from '@/views/projects/detail/header.vue';
import DetailLeftSide from '@/views/projects/detail/leftSide.vue';
import Tender from '@/views/projects/detail/tender/index.vue';
import RetractPrice from '@/views/projects/detail/combinedPrice/retractPrice.vue';
import Cookies from 'js-cookie';
import { previewBySequenceNbr } from '@/api/projectDetail';

export default Vue.extend({
  components: {
    RetractPrice,
    DetailHeader,
    DetailLeftSide,
    Tender,
  },
  data() {
    return {
      ggId: '', // 招标通传过来的ggId
      allData: {
        // total: 159357,
        // constructName: '测试项目',
        // singleName: '室内总体',
        // unitName: '电气工程',
        // itemList: []
      } as any,
      treeList: [
        // {
        //   sequenceNbr: 1,
        //   name: '测试项目',
        //   type: '01',
        //   children: [
        //     {
        //       sequenceNbr: 2,
        //       name: '室内总体',
        //       type: '02',
        //       children: [
        //         {
        //           sequenceNbr: 3,
        //           name: '电气工程',
        //           type: '03',
        //           children: [],
        //         },
        //       ],
        //     },
        //   ],
        // },
      ] as any, // 左侧属性结构数据
    };
  },
  computed: {
    ...mapGetters([
      'isUnitProject',
      'isSingleProject',
      'isUnfold',
      'isTenderUnfold',
      'projectBidSequenceNbr',
      'isRetractUnfold',
      'loginVisible',
    ]),
    tenderStyle(): { height: string; minHeight: string } {
      let tenderHeight;
      let minHeight;
      tenderHeight = '100%';
      minHeight = '500px';
      return {
        minHeight,
        height: tenderHeight,
      };
    },
  },
  created() {
    this.$store.commit('SET_SELECTSTATUS', false);
    this.$store.commit('SET_READONLY', true);
  },
  mounted() {
    this.ggId = this.$route.query?.ggId as string;
    // const millisecond = new Date().getTime();
    // const expiresTime = new Date(millisecond + 60 * 1000 * 10);
    console.log('ggId', this.ggId);
    this.ggId && Cookies.set('ggId', this.ggId, { expires: 3 });
    this.previewBySequenceNbr();
    //this.dragControllerDiv();
  },
  directives: {
    drag: {
      // 指令的定义
      bind: function (el) {
        let oDiv = el; // 获取当前元素
        document.onselectstart = () => false;
        oDiv.onmousedown = (e) => {
          let currentEl = document.getElementsByClassName(
            'ant-layout-sider'
          )[0] as HTMLTableElement;
          // 算出鼠标相对元素的位置
          let disX = e.clientX - oDiv.offsetLeft;
          document.onmousemove = (e) => {
            let left = 0;
            if (e.clientX - disX > 500) {
              left = 500;
            } else {
              left = e.clientX - disX > 0 ? e.clientX - disX : 0;
            }
            // oDiv.style.left = left + 'px';
            currentEl.style.maxWidth = left + 'px';
            currentEl.style.flex = '0 0 0' + left + 'px';
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
            document.onselectstart = null;
          };
        };
      },
    },
  },
  methods: {
    cancel() {
      this.$store.commit('SET_LOGINVISIBLE', false);
    },
    /**
     * 获取页面数据
     */
    previewBySequenceNbr() {
      previewBySequenceNbr(Cookies.get('ggId')).then((res: any) => {
        console.log('res', res);
        if (res.status === 200) {
          this.allData = res.result;
          this.treeList = [
            {
              sequenceNbr: 1,
              name: this.allData.constructName,
              type: '01',
              children: [
                {
                  sequenceNbr: 2,
                  name: this.allData.singleName,
                  type: '02',
                  children: [
                    {
                      sequenceNbr: 3,
                      name: this.allData.unitName,
                      type: '03',
                      children: [],
                    },
                    {
                      sequenceNbr: 4,
                      name: this.allData.unitName2,
                      type: '03',
                      children: [],
                    },
                  ],
                },
              ],
            },
          ];
        }
      });
    },
    goLogin() {
      this.$setGlobalState({ isVisitor: 1 });
      history.pushState(null, '', '/2');
    },
  },
});
